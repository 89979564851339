<template>
  <div class="w-full">
    <section v-if="!isMobile" class="min-h-screen relative w-full">
    <div class="w-full py-36 flex justify-center items-center">
      <div class="text-center z-10">
        <h1 class="sm:text-5xl text-3xl text-white font-brand font-bold mb-6">Ready to Join?</h1>
        <h2 class="sm:text-3xl text-lg text-turquoise-500 font-brand font-bold">Oxbull Tiers comes in 3 sizes</h2>
      </div>

      <!-- <div class="sm:h-[500px] sm:w-[500px] h-[50vw] w-[50vw] absolute bg-turquoise-500 blur-[200px] rounded-full z-0 opacity-30"></div> -->
      <img class="w-[2000px] h-[2000px] object-contain opacity-20 absolute" src="/blur.png" alt="Oxbull primary colored blur effect" >
    </div>

    <div class="w-full sticky top-0 h-screen flex justify-start sm:justify-center items-center z-20">
      <div class="w-full flex justify-center items-center  absolute  top-1 sm:top-auto p-4">
        <div class="w-full max-w-[300px] group flex justify-center items-center flex-col border p-2 rounded-md hover:border-white/20 hover:bg-white/20 hover:backdrop-blur-sm duration-300 border-transparent overflow-hidden">
        <div class="sm:w-[250px] sm:h-[250px] my-8  w-full aspect-square -mt-8 sm:mt-0">
          <ClientOnly>
            <TierBadge3d :isActive="true" class="opacity-80 group-hover:opacity-100 duration-300" :activeBadge="activeBadge"></TierBadge3d>
          </ClientOnly>
        </div>
        <a :id="`stake_tier_section_tier${ activeBadge + 1 }`" href="/app/stake" target="_blank" class="w-full z-20 pointer-events-auto group-hover:opacity-100 opacity-0 btn bg-white rounded-md text-xl h-auto p-2 px-4 font-brand font-bold text-black border-none hover:text-white">
          Stake Tier
          <span v-if="activeBadge == 0">One</span>
          <span v-if="activeBadge == 1">Two</span>
          <span v-if="activeBadge == 2">Three</span>
        </a>
      </div>
      </div>
    </div>

    <div class="w-full flex justify-center items-start">
      <div class="w-full flex justify-start items-start">
        <ScrollObserver @update="badgeScrollUpdate" class="w-full mt-[-100vh]">
          <ScrollTriggerGroup class="space-y-3 text-3xl w-full">
            <ScrollTrigger v-for="tier in tiers" v-slot="{ isActive }" class="h-[100vh] w-full flex justify-center items-center">
              <div :class="[isActive ? '' : 'opacity-20']" class="w-full h-full sm:px-3 px-2 overflow-hidden duration-300">
                <div class="w-full h-full bg-white/10  rounded-md sm:grid sm:grid-cols-1 sm:grid-rows-2 sm:justify-center justify-between items-center relative flex flex-col">
                  <div class="absolute inset-0 bg-center bg-grid-white/10 bg-grid-24"></div>

                  <div class="w-full text-center h-full flex flex-col sm:justify-end justify-end items-center pb-4">
                    <div class="text-white text-xl mb-8">
                      {{ tier.subtitle }}
                    </div>

                    <span :class="`sm:text-9xl text-6xl font-brand font-bold text-transparent bg-clip-text bg-gradient-to-r ${tier.style}`">{{ tier.title }}</span>
                  </div>

                  <div class="w-full sm:h-full flex flex-col justify-end items-center p-2">
                    <div class="w-full grid xl:grid-cols-6 sm:grid-cols-3 grid-cols-1 gap-1">
                      <div v-for="attr in tier.attributes" class="w-full grid grid-cols-[2.5rem_1fr] grid-rows-1 sm:grid-cols-1 sm:grid-rows-3 justify-center items-center pt-4 pb-2 rounded-md xl:aspect-square p-2 text-center backdrop-blur-sm" :class="[attr.disabled ? 'opacity-20 bg-zinc-600' : 'bg-white/10']">
                        <div class="w-full row-span-2 flex justify-center items-center">
                          <Icon v-if="'icon' in attr" class="sm:w-16 sm:h-16 w-10 h-10 text-white" :name="attr.icon"></Icon>
                          <div v-if="'title' in attr" class="sm:w-16 sm:h-16 w-10 h-10 flex justify-center items-center">
                            <p class="text-white font-brand font-bold sm:text-2xl text-xl">{{ attr.title }}</p>
                          </div>
                        </div>

                        <p :class="[attr.disabled ? 'line-through' : '']" class="sm:text-base text-sm text-left sm:text-center pl-4 sm:pl-0">{{ attr.content }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollTrigger>
          </ScrollTriggerGroup>
        </ScrollObserver>
      </div>
    </div>
  </section>


  <section v-if="isMobile" class="w-full min-h-screen p-1">


<div class="w-full py-24 flex justify-center items-center">
  <div class="text-center z-10">
    <h1 class="sm:text-5xl text-3xl text-white font-brand font-bold mb-6">Ready to Join?</h1>
    <h2 class="sm:text-3xl text-lg text-turquoise-500 font-brand font-bold">Oxbull Tiers comes in 3 sizes</h2>
  </div>

  <div class="h-[50vw] w-[50vw] absolute bg-turquoise-500 blur-[200px] rounded-full z-0 opacity-30"></div>
</div>


<div class="p-2 relative">
  <div class="w-full sticky top-[4.5rem] z-20" >

    <div class="border rounded-xl bg-white  text-black p-2 mb-2">
      <p class="text-3xl text-center font-brand font-bold" > Tier
        <span v-if="activeBadge == 0">One</span>
          <span v-if="activeBadge == 1">Two</span>
          <span v-if="activeBadge == 2">Three</span>
      </p>
    </div>

    <div class="w-full h-[30vh] bg-black rounded-xl flex justify-center items-center relative border ">

      <div class=" w-[8rem] h-[8rem] flex justify-center items-center">
        <ClientOnly>
          <TierBadge3d :isActive="true" class="-mt-12 z-20 w-full h-full" :activeBadge="activeBadge"></TierBadge3d>
        </ClientOnly>
      </div>

      <span :class="`absolute text-5xl font-brand font-bold text-transparent bg-clip-text bg-gradient-to-r ${activeTier.style} -mt-12`">{{ activeTier.title }}</span>


      <div class="absolute inset-0 bg-center bg-grid-white/30 bg-grid-12"></div>


      <div class="w-full absolute bottom-0 p-2 z-20" >
        <a :id="`stake_tier_section_tier${ activeBadge + 1 }`" href="/app/stake" target="_blank" class="btn w-full bg-white font-brand text-xl font-bold text-black" >Go to Stake</a>
      </div>
      
    </div>

    

  </div>

  <ScrollObserver @update="badgeScrollUpdate" class="w-full">
          <ScrollTriggerGroup class="space-y-3 text-3xl w-full">
            <ScrollTrigger v-for="tier in tiers" v-slot="{ isActive }" class="w-full pt-[100px]">
              <div :class="[isActive?'bg-zinc-900 border-zinc-800':'opacity-50 border-white/20']" class="w-full mt-4 p-2 border rounded-xl ">
    


    <div class="w-full space-y-1">
      <div v-for="attr in tier.attributes" class="w-full grid grid-cols-[2rem_1fr] grid-rows-1 justify-center items-center rounded-md xl:aspect-square p-1 text-center backdrop-blur-sm" :class="[attr.disabled ? 'opacity-20 ' : '']">
                        <div class="w-full row-span-2 flex justify-center items-center bg-white/10 aspect-square rounded-md p-1">
                          <Icon v-if="'icon' in attr" class="w-6 h-6 text-white" :name="attr.icon"></Icon>
                          <div v-if="'title' in attr" class="w-6 h-6 flex justify-center items-center">
                            <p class="text-white font-brand font-bold sm:text-2xl text-sm">{{ attr.title }}</p>
                          </div>
                        </div>

                        <p :class="[attr.disabled ? 'line-through' : '']" class="sm:text-base text-sm text-left sm:text-center pl-4 sm:pl-0">{{ attr.content }}</p>
                      </div>
    </div>


  </div>
            </ScrollTrigger>
          </ScrollTriggerGroup>
        </ScrollObserver>

  

<!--  <div class="w-full mt-4 p-2 border rounded-xl border-white/20">
    
  

    <div class="w-full grid grid-cols-3 gap-2">
      <div v-for="i in 6" class="w-full aspect-square border border-white/20 rounded-xl"></div>
    </div>


  </div>

  <div class="w-full mt-4 p-2 border rounded-xl border-white/20">
    
    

    <div class="w-full grid grid-cols-3 gap-2">
      <div v-for="i in 6" class="w-full aspect-square border border-white/20 rounded-xl"></div>
    </div>


  </div>-->

</div>


  </section>

  </div>

</template>

<script lang="ts" setup>


import { useScreens } from "vue-screen-utils";
import { debounce } from "lodash";

const { mapCurrent } = useScreens({
  xs: "0px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
});

const isMobile = mapCurrent({
  xs: true,
  sm: false,
  md: false,
  lg: false,
});

const tiers = [
  {
    title: "Tier One",
    style: "from-[#ffe281] to-[#ffc900]",
    subtitle: "Stake 8000 $OXB",
    attributes: [
      {
        icon: "mingcute:safety-certificate-fill",
        content: "Guaranteed Allocation",
      },
      {
        icon: "mdi:size-l",
        content: "Large Allocation Size",
      },
      {
        title: "IDO",
        content: "Access to IDO",
      },
      {
        icon: "basil:unlock-solid",
        content: "Access to Private Sale",
      },
      {
        icon: "cib:telegram-plane",
        content: "Tier One Private Telegram Group",
      },
      {
        icon: "mdi:vote",
        content: "Voting Power",
      },
    ],
  },
  {
    title: "Tier Two",
    style: "from-[#ffffff] to-[#C0C0C0]",
    subtitle: "Stake 1000 to 4000 $OXB",
    attributes: [
      {
        icon: "mingcute:safety-certificate-fill",
        content: "Guaranteed Allocation",
      },
      {
        icon: "mdi:size-m",
        content: "Medium Allocation Size",
      },
      {
        title: "IDO",
        content: "Access to IDO",
      },
      {
        icon: "basil:unlock-solid",
        content: "Unlock Private Sale with Private Sale Pass",
      },
      {
        icon: "cib:telegram-plane",
        content: "Tier Two Private Telegram Group",
      },
      {
        icon: "mdi:vote",
        disabled: "true",
        content: "Voting Power",
      },
    ],
  },
  {
    title: "Tier Three",
    style: "from-[#e0a76e] to-[#cd7f32]",
    subtitle: "Stake at least 50 $OXB",
    attributes: [
      {
        icon: "mingcute:safe-flash-fill",
        content: "First Come First Serve",
      },
      {
        icon: "mdi:size-s",
        content: "Small Allocation Size",
      },
      {
        title: "IDO",
        content: "Access to IDO",
      },
      {
        icon: "basil:unlock-solid",
        content: "Unlock Private Sale with Private Sale Pass",
      },
      {
        icon: "cib:telegram-plane",
        content: "Tier Three Private Telegram Group",
      },
      {
        icon: "mdi:vote",
        disabled: "true",
        content: "Voting Power",
      },
    ],
  },
];

const activeBadge = ref(0);

const activeTier = computed(() => {
  return tiers[activeBadge.value];
});

const badgeScrollUpdate = debounce((e) => {
  if(e == -1) {
    if (activeBadge.value == 2) {
      return
    } else {
      activeBadge.value = 0
    }
  }else{
    activeBadge.value = e;
  }
  e > -1 && dataLayer.push({
    event: "scroll_to_stake_tier",
    page: window.location.pathname,
    tier_key: `tier${ e + 1 }`
  })
}, 150);
</script>

<style></style>
